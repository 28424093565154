<template>
  <div>
    <div class="text-h3">Content Page</div>
    <div class="text-h6">{{ $route.params.slug }}</div>
    <v-container>
      <vue-simple-markdown :source="pageData.content"></vue-simple-markdown>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Content',
  data: () => ({
    pageData: Object
  }),
  created () {

  }
}
</script>

<style scoped>

</style>
